html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
}

html {
    overflow: hidden;
}

/* Body is set to hidden when a dropdown is open */
body {
    overflow: visible !important;
}

.crosshair { 
  cursor: crosshair !important;
}

/* New Map CSS For Esri*/
.esri-coordinates {
  background-color: #fff;
}

.esri-layer-control {
  background-color: #fff;
  width: 300px;
}

.parentContainer {
  display: flex;
  box-shadow: none !important;
}

.parentContainer div:not(:last-child){
  margin-right: 10px;
}

#drainagemap .esri-legend__service .esri-widget__heading {
  display: none;
}

.esri-view-surface--inset-outline:focus::after{
  content: none !important;
}

.esri-popup__header .esri-popup__header-container h2 {
  background-color: #bba;
}

.esri-popup__header-title{
  width: 100%;
}

.picto { 
  padding-top: 2px;
  padding-left: 2px;
	background: url("./images/maptools.png") 2px -172px;
	background-color:#fff;
	background-repeat: no-repeat;
}

.esri-legend__service {
  padding: 0px 12px 0px 15px !important;
}

.esri-legend__layer-table {
  margin-bottom: 0px !important;
}

.esri-legend__message {
  padding: 0px 1em 0px 1em !important;
}